import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//Comment fetch slice
export const fetchComplainComments = createAsyncThunk("fetchComplainComments", async ({ id }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`complain/comment/list?id=${id}`, config);

	return response.data.data;
});

const complainCommentListSlice = createSlice({
	name: "complainCommentList",
	initialState: {
		isLoading: false,
		data: [],
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchComplainComments.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchComplainComments.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchComplainComments.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default complainCommentListSlice.reducer;
