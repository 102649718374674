import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchLevelDetail = createAsyncThunk(
  "fetchLevelDetail",
  async ({ id }) => {
    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `level/detail?id=${id}`,
      config
    );
    
    return response.data.data;
  }
);

const levelDetailSlice = createSlice({
  name: "levelDetail",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
 
  reducers: {
    resetStateLevelDetail: (state) => {
      state.isLoading = false;
      state.error = null;
      state.data = null;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchLevelDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchLevelDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;

    });
    builder.addCase(fetchLevelDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetStateLevelDetail } = levelDetailSlice.actions;
export default levelDetailSlice.reducer;