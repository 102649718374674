import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const createWFour = createAsyncThunk(
    "createWFour",
    async ({data}, {rejectWithValue}) => {
        try{
            const token = localStorage.getItem("auth_token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            const response = await axiosHttp.post(`wfour/create`, data, config);
            message.success("Your W-Four has been created successfully!");
            return response.data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data);
        }
    }
);

const createWFourSlice = createSlice({
    name: "createWFour",
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
    },

    extraReducers: (builder) => {
        builder.addCase(createWFour.pending, (state, action) => {
            state.isLoading = true;
            state.isError = false;
        });

        builder.addCase(createWFour.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.data = action.payload;
        });

        builder.addCase(createWFour.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.error = action.payload;
        });
    },
});

export default createWFourSlice.reducer;