import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const fetchCreateStockTakeCount = createAsyncThunk(
  "fetchCreateStockTakeCount",
  async ({ data, form, setOpen },{rejectWithValue}) => {
    try{
          const token = localStorage.getItem("auth_token");
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
          const response = await axiosHttp.post(`stock-take/count`, data, config);
          form.resetFields();
          message.success("Count Saved successfully!");
          return response.data;
      } catch (err) {
        if (!err.response) {
            throw err
        }
          return rejectWithValue(err.response.data);
    }
  }
);

const createStockTakeCountSlice = createSlice({
  name: "createStockTake",
  initialState: {
    isLoading: false,
    error: null,
    data:null,
    status: "",
    
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCreateStockTakeCount.pending, (state, action) => {
      state.isLoading = true;
      state.status = ""
    });

    builder.addCase(fetchCreateStockTakeCount.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log(action);
      state.data = action.payload;
      state.status = action.payload.status;
    });
    
    builder.addCase(fetchCreateStockTakeCount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default createStockTakeCountSlice.reducer;
