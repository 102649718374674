import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchMilestones = createAsyncThunk(
  "fetchMilestones",
  async ({id}) => {
   
    const token = localStorage.getItem("auth_token");
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": '*',
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    };

    const response = await axiosHttp.get(
      `milestone/list?bip=${id}`,
      config
    );

    return response.data.data;
  }
);

const milestoneListSlice = createSlice({
  name: "milestoneList",
  initialState: {
    isLoading : false,
    data      : null,
    error     : null,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMilestones.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMilestones.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchMilestones.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default milestoneListSlice.reducer;