import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchMeetingsW4List = createAsyncThunk("fetchMeetingsW4List", async ({ page, limit, meetingId }) => {
	const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`meeting/w4list?meetingId=${meetingId}&page=1&limit=100`, config);
	return response.data.data;
});

const MeetingW4ListSlice = createSlice({
	name: "MeetingW4ListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMeetingsW4List.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMeetingsW4List.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchMeetingsW4List.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default MeetingW4ListSlice.reducer;
