import CryptoJS from "crypto-js";

class PermissionManager {
	static secretKey = process.env.REACT_APP_SECRET_KEY;

	static _encrypt(data) {
		return CryptoJS.AES.encrypt(data, PermissionManager.secretKey).toString();
	}

	static _decrypt(data) {
		const bytes = CryptoJS.AES.decrypt(data, PermissionManager.secretKey);
		return bytes.toString(CryptoJS.enc.Utf8);
	}

	static setItem(key, value) {
		try {
			const valueString = JSON.stringify(value);
			const encryptedValue = this._encrypt(valueString);
			localStorage.setItem(key, encryptedValue);
		} catch (error) {
			console.error(`Error setting item in localStorage: ${error}`);
		}
	}

	static getItem(key) {
		try {
			const encryptedValue = localStorage.getItem(key);
			if (encryptedValue) {
				const decryptedValue = this._decrypt(encryptedValue);
				return JSON.parse(decryptedValue);
			}
			return null;
		} catch (error) {
			console.error(`Error getting item from localStorage: ${error}`);
			return null;
		}
	}

	static removeItem(key) {
		try {
			localStorage.removeItem(key);
		} catch (error) {
			console.error(`Error removing item from localStorage: ${error}`);
		}
	}

	static clear() {
		try {
			localStorage.clear();
		} catch (error) {
			console.error(`Error clearing localStorage: ${error}`);
		}
	}
}

export default PermissionManager;
