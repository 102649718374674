import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const warrantyRoutes = [
	{
		key: "serial-number-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/serial-number/list`,
		component: React.lazy(() => import("views/app-views/warranty/serial-numbers/pages/SerialNumberList")),
	},
	{
		key: "serial-number-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/serial-number/detail/:id`,
		component: React.lazy(() => import("views/app-views/warranty/serial-numbers/pages/SerialNumberDetail")),
	},
	{
		key: "serial-number-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/serial-number/add`,
		component: React.lazy(() => import("views/app-views/warranty/serial-numbers/pages/SerialNumberAdd")),
	},
	{
		key: "order-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/order/list`,
		component: React.lazy(() => import("views/app-views/warranty/orders/pages/OrderList")),
	},
	{
		key: "order-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/order/add`,
		component: React.lazy(() => import("views/app-views/warranty/orders/pages/OrderAdd")),
	},
	{
		key: "job-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/job/list`,
		component: React.lazy(() => import("views/app-views/warranty/jobs/pages/JobList")),
	},
	{
		key: "customer-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/customer/list`,
		component: React.lazy(() => import("views/app-views/warranty/customer/pages/CustomerList")),
	},
	{
		key: "serial-number-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/serial-number/list`,
		component: React.lazy(() => import("views/app-views/warranty/serial-numbers/pages/SerialNumberList")),
	},
	{
		key: "serial-number-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/serial-number/detail/:id`,
		component: React.lazy(() => import("views/app-views/warranty/serial-numbers/pages/SerialNumberDetail")),
	},
	{
		key: "serial-number-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/serial-number/add`,
		component: React.lazy(() => import("views/app-views/warranty/serial-numbers/pages/SerialNumberAdd")),
	},
	{
		key: "order-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/order/list`,
		component: React.lazy(() => import("views/app-views/warranty/orders/pages/OrderList")),
	},
	{
		key: "order-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/order/add`,
		component: React.lazy(() => import("views/app-views/warranty/orders/pages/OrderAdd")),
	},
	{
		key: "order-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/order/order-detail/:id`,
		component: React.lazy(() => import("views/app-views/warranty/orders/pages/OrderDetail")),
	},
	{
		key: "job-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/job/list`,
		component: React.lazy(() => import("views/app-views/warranty/jobs/pages/JobList")),
	},
	{
		key: "customer-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/customer/list`,
		component: React.lazy(() => import("views/app-views/warranty/customer/pages/CustomerList")),
	},
	{
		key: "dealer-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/dealer/list`,
		component: React.lazy(() => import("views/app-views/warranty/dealer/pages/DealerList")),
	},
	{
		key: "product-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/product/list`,
		component: React.lazy(() => import("views/app-views/warranty/product/pages/ProductList")),
	},

	{
		key: "model-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/model/modelList`,
		component: React.lazy(() => import("views/app-views/warranty/model/pages/ModelList")),
	},

	{
		key: "warehouse-in",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/warehousein/scan`,
		component: React.lazy(() => import("views/app-views/warranty/warehouseIn/pages/WarehouseIn")),
	},
	{
		key: "warehouse-in-bulk",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/warehousein/bulk`,
		component: React.lazy(() => import("views/app-views/warranty/warehouseIn/pages/WarehouseInBulkUpload")),
	},
	{
		key: "order-upload",
		exact: true,
		path: `${APP_PREFIX_PATH}/warranty/order/upload`,
		component: React.lazy(() => import("views/app-views/warranty/orders/pages/OrderUpload")),
	},
];
