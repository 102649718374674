import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const newsRoutes = [
	{
		key: "newsList",
		exact: true,
		path: `${APP_PREFIX_PATH}/news/list`,
		component: React.lazy(() => import("views/app-views/news/pages/NewsList")),
	},
	{
		key: "news-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/news/add`,
		component: React.lazy(() => import("views/app-views/news/pages/NewsAdd")),
	},
	{
		key: "news-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/news/edit/:id`,
		component: React.lazy(() => import("views/app-views/news/pages/NewsEdit")),
	},
	{
		key: "news-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/news/detail/:id`,
		component: React.lazy(() => import("views/app-views/news/pages/NewsDetail")),
	},
];
