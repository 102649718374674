import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchCategoryTypeList = createAsyncThunk("fetchCategoryTypeList", async ({ page, filters, limit }) => {
	const categoryType = filters && filters.categoryType !== undefined ? `${filters.categoryType}` : "";

	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(
		//   `categorytype/list?page=${page}&limit=${limit}&categoryTypeId=${categoryType}`,
		`categorytype/list?limit=${limit}&page=${page}&categoryTypeId=${categoryType}`,
		config
	);

	return response.data.data;
});

const categoryTypeListSlice = createSlice({
	name: "categoryTypeList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchCategoryTypeList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchCategoryTypeList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchCategoryTypeList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default categoryTypeListSlice.reducer;
