import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const orgChartRoutes = [
	{
		key: "org-chart",
		exact: true,
		path: `${APP_PREFIX_PATH}/org-chart`,
		component: React.lazy(() => import("views/app-views/org-chart/pages/OrgChart")),
	},
	{
		key: "org-chart-id",
		exact: true,
		path: `${APP_PREFIX_PATH}/org-chart/:id`,
		component: React.lazy(() => import("views/app-views/org-chart/pages/OrgChart")),
	},
];
