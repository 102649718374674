import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchEmployeeList = createAsyncThunk("fetchEmployeeList", async ({ page, filters, limit, compLimit = true }) => {
	const keyword = filters && filters.keyword !== undefined ? `${filters.keyword}` : "";
	const company = filters && filters.company !== undefined ? `${filters.company}` : "";
	const location = filters && filters.location !== undefined ? `${filters.location}` : "";
	const vc = filters && filters.vc !== undefined ? `${filters.vc}` : "";
	const department = filters && filters.department !== undefined ? `${filters.department}` : "";
	const stratum = filters && filters.stratum !== undefined ? `${filters.stratum}` : "";
	const level = filters && filters.level !== undefined ? `${filters.level}` : "";
	const designation = filters && filters.designation !== undefined ? `${filters.designation}` : "";
	const resignedStatus = filters && filters.resignedStatus !== undefined ? `${filters.resignedStatus}` : "";

	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(
		`employee/list?page=${page}&limit=${limit}&keyword=${keyword}&company=${company}&locationId=${location}&valueChainId=${vc}&departmentId=${department}&stratumId=${stratum}&levelId=${level}&designationId=${designation}&resignedStatus=${resignedStatus}`,
		config
	);

	return response.data.data;
});

const employeeListSlice = createSlice({
	name: "employeeList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchEmployeeList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchEmployeeList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchEmployeeList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default employeeListSlice.reducer;
