import axiosHttp from "services/axiosHttp";
import { FCM_TOKEN } from "constants/AuthConstant";
import { API_BASE_URL } from "configs/AppConfig";
import { initializeFirebaseMessaging } from "services/FirebaseMessaging";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// action
export const initFirebaseNotification = createAsyncThunk("initFirebaseNotification", async ({ token }, { rejectWithValue }) => {
	try {
		let fcmToken = localStorage.getItem(FCM_TOKEN);

		// If FCM token is null, initialize Firebase Messaging to get a new token
		if (!fcmToken) {
			await initializeFirebaseMessaging();
			fcmToken = localStorage.getItem(FCM_TOKEN);

			// If still null after attempting to get the token, throw an error
			if (!fcmToken) {
				throw new Error("Unable to retrieve FCM token.");
			}
		}

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const response = await axiosHttp.post(`${API_BASE_URL}user/fcm/update?platform=web&fcm_token=${fcmToken}`, config);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const firebaseNotificationSlice = createSlice({
	name: "firebaseNotificationSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder
			.addCase(initFirebaseNotification.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(initFirebaseNotification.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(initFirebaseNotification.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload;
			});
	},
});

export default firebaseNotificationSlice.reducer;
