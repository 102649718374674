import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchComplainList = createAsyncThunk("fetchComplainList", async ({ page, limit, filters }) => {
	const token = localStorage.getItem("auth_token");

	const status = filters && filters.status ? `${filters.status}` : "";

	const keyword = filters && filters.keyword ? `${filters.keyword}` : "";

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`complain/list?page=${page}&limit=${limit}&status=${status}&keyword=${keyword}`, config);

	return response.data.data;
});

const complainListSlice = createSlice({
	name: "complainList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchComplainList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchComplainList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchComplainList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default complainListSlice.reducer;
