import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const profileRoutes = [
	{
		key: "profile",
		exact: true,
		path: `${APP_PREFIX_PATH}/profile`,
		component: React.lazy(() => import("views/app-views/profile/index")),
	},
];
