import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const meetingRoutes = [
	{
		key: "meetings",
		exact: true,
		path: `${APP_PREFIX_PATH}/meetings`,
		component: React.lazy(() => import("views/app-views/meetings/my-meetings/pages/MeetingsList")),
	},
	{
		key: "meeting-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/meetings/:id`,
		component: React.lazy(() => import("views/app-views/meetings/my-meetings/pages/MeetingDetail")),
	},
	{
		key: "company-meetings",
		exact: true,
		path: `${APP_PREFIX_PATH}/meetings/company-meetings`,
		component: React.lazy(() => import("views/app-views/meetings/company-meetings/pages/CompanyMeetingsList")),
	},
	{
		key: "company-meetings-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/meetings/company-meetings/:id`,
		component: React.lazy(() => import("views/app-views/meetings/company-meetings/pages/CompanyMeetingDetail")),
	},
];
