import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN, USER } from "constants/AuthConstant";
import axiosHttp from "services/axiosHttp";
import PermissionManager from "utils/class/PermissionManager";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchMyDetail = createAsyncThunk("fetchMyDetail", async () => {
	const config = {
		headers: {
			Authorization: `Bearer ${PermissionManager.getItem(AUTH_TOKEN)}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`${API_BASE_URL}user/me`, config);
	PermissionManager.setItem(USER, response.data.data);
	return response.data.data;
});

const MyDetailSlice = createSlice({
	name: "myDetail",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMyDetail.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMyDetail.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchMyDetail.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default MyDetailSlice.reducer;
