import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const sopRoutes = [
	{
		key: "sop-admin-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/sop/admin/list`,
		component: React.lazy(() => import("views/app-views/sop/pages/administrative-SOPs/SopListView")),
	},
	{
		key: "create-sop-admin",
		exact: true,
		path: `${APP_PREFIX_PATH}/sop/admin/create`,
		component: React.lazy(() => import("views/app-views/sop/pages/administrative-SOPs/CreateSopView")),
	},
	{
		key: "sop-detail-admin",
		exact: true,
		path: `${APP_PREFIX_PATH}/sop/admin/detail/:id`,
		component: React.lazy(() => import("views/app-views/sop/pages/administrative-SOPs/SopDetailView")),
	},
	{
		key: "sop-edit-admin",
		exact: true,
		path: `${APP_PREFIX_PATH}/sop/admin/edit/:id`,
		component: React.lazy(() => import("views/app-views/sop/pages/administrative-SOPs/UpdateSopView")),
	},
	{
		key: "my-sop-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/sop/my/list`,
		component: React.lazy(() => import("views/app-views/sop/pages/my-SOPs/MySOPListView")),
	},
	{
		key: "my-sop-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/sop/my/detail/:id`,
		component: React.lazy(() => import("views/app-views/sop/pages/my-SOPs/MySOPDetailView")),
	},
];
