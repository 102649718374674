import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchValueChainList = createAsyncThunk(
  "fetchValueChainList",
  async ({ page, filters, limit }) => {
    const keyword = filters && filters.keyword !== undefined ? `${filters.keyword}` : "";
    const company = filters && filters.company !== undefined ? `${filters.company}` : "" ;

    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `vc/list?page=${page}&limit=${limit}&keyword=${keyword}&company=${company}`,
      config
    );

    return response.data.data;
  }
);

const valueChainListSlice = createSlice({
  name: "valueChainList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchValueChainList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchValueChainList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchValueChainList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default valueChainListSlice.reducer;