import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchBiList = createAsyncThunk("fetchBiList", async ({ page, limit, type, filters }) => {
	const token = localStorage.getItem("auth_token");

	const method = filters?.allBi == 1 ? true : false;
	const company = filters?.company ? filters?.company : "";
	const status = filters?.status ? filters?.status : "";

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`bi/list?page=${page}&limit=${limit}&company=${company}&type=${type}&status=${status}&allBi=${method}&notDone=true`, config);

	return response.data.data;
});

const biListSlice = createSlice({
	name: "biList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchBiList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchBiList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchBiList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default biListSlice.reducer;
