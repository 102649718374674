import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateModel = createAsyncThunk(
  "updateModel",
  async ({ id, data, form }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.patch(
      `product/model/update?Id=${id}`,
      data,
      config
    );

    form.resetFields();
    message.success("Model successfully updated!");

    return response.data;
  }
);

const ModelUpdateSlice = createSlice({
  name: "ModelUpdateSlice",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updateModel.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateModel.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateModel.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default ModelUpdateSlice.reducer;
