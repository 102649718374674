import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchTicketDetail = createAsyncThunk("fetchTicketDetail", async (id) => {
	const response = await axiosHttp.get(`desk/ticket/detail/${id}`);
	return response.data.data;
});

const TicketDetailSlice = createSlice({
	name: "TicketDetailSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchTicketDetail.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchTicketDetail.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchTicketDetail.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default TicketDetailSlice.reducer;
