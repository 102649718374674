import axiosHttp from "services/axiosHttp";
import PermissionManager from "utils/class/PermissionManager";
import { API_BASE_URL } from "configs/AppConfig";
import { PERMISSIONS } from "constants/AuthConstant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action to fetch permissions
export const fetchPermission = createAsyncThunk("fetchPermission", async (tokenNo, { rejectWithValue }) => {
	try {
		let token = localStorage.getItem("auth_token");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};

		const response = await axiosHttp.get(`${API_BASE_URL}permission/list?platform=web`, config);
		const newPermissions = response.data.data;

		if (newPermissions && newPermissions.modules && newPermissions.modules.length > 0) {
			PermissionManager.setItem(PERMISSIONS, newPermissions);
		} else {
			const existingPermissions = PermissionManager.getItem(PERMISSIONS);
			if (existingPermissions) {
				PermissionManager.setItem(PERMISSIONS, existingPermissions);
			}
		}

		return newPermissions;
	} catch (error) {
		return rejectWithValue(error.response?.data || "An error occurred while fetching permissions");
	}
});

const PermissionSlice = createSlice({
	name: "permission",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPermission.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchPermission.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(fetchPermission.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload;
			});
	},
});

export default PermissionSlice.reducer;
