import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

/**
 * This Slice used for get all dealer without pagination for dropdown actions
 */

export const fetchALLDealerList = createAsyncThunk(
  "fetchALLDealerList",
  async () => {
    const token = localStorage.getItem("auth_token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const response = await axiosHttp.get(
      `businesspartner/list?page=1&limit=1000000`,
      config
    );

    return response.data.data;
  }
);

const AllDealersListSlice = createSlice({
  name: "AllDealersListSlice",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchALLDealerList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchALLDealerList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchALLDealerList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default AllDealersListSlice.reducer;
