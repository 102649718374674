import React from "react";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import { dealerRoutes } from "views/app-views/dealer/config/routes";
import { b2cRoutes } from "views/app-views/b2c/config/routes";
import { gisSettingsRoutes } from "views/app-views/gis/gis-settings/config/routes";
import { analyticsGISRoutes } from "views/analytics-views/dashboards/sub-dashboards/internal/gis-map/config/routes";
import { analyticsHomeRoutes } from "views/analytics-views/dashboards/config/routes";
import { analyticsLeaderBoardRoutes } from "views/analytics-views/dashboards/sub-dashboards/internal/leaderboard/config/routes";
import { stockTakeRoutes } from "views/app-views/stock-take/config/routes";
import { sopRoutes } from "views/app-views/sop/config/routes";
import { meetingRoutes } from "views/app-views/meetings/config/routes";
import { warrantyRoutes } from "views/app-views/warranty/config/routes";
import { goalBipRoutes } from "views/app-views/goals/config/routes";
import { wFourRoutes } from "views/app-views/w-four/config/routes";
import { biBnRoutes } from "views/app-views/burning-issues/config/routes";
import { newsRoutes } from "views/app-views/news/config/routes";
import { complainRoutes } from "views/app-views/complains/config/routes";
import { employeeRoutes } from "views/app-views/employee/config/routes";
import { organizationRoutes } from "views/app-views/organization/config/routes";
import { orgChartRoutes } from "views/app-views/org-chart/config/routes";
import { profileRoutes } from "views/app-views/profile/config/routes";
import { dashboardRoutes } from "views/app-views/dashboards/config/routes";
import { settingsRoutes } from "views/app-views/settings/config/routes";
import { productRoutes } from "views/app-views/product/config/routes";
import { analyticsExternalRoutes } from "views/analytics-views/dashboards/sub-dashboards/external/config/routes";
import { dashboardMGMTRoutes } from "views/analytics-views/dashboards-mgmt/config/routes";
import { adminModuleRoutes } from "views/admin-views/config/routes";
import { analyticsGISRFRoutes } from "views/analytics-views/dashboards/sub-dashboards/internal/gis-rf-map/config/routes";
import { ticketRoutes } from "views/app-views/tickets/config/routes";

export const publicRoutes = [
	{
		key: "login",
		path: `${AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() => import("views/auth-views/authentication/login")),
	},
	{
		key: "register",
		path: `${AUTH_PREFIX_PATH}/register`,
		component: React.lazy(() => import("views/auth-views/authentication/register")),
	},
	{
		key: "forgot-password",
		path: `${AUTH_PREFIX_PATH}/forgot-password`,
		component: React.lazy(() => import("views/auth-views/authentication/forgot-password")),
	},
	{
		key: "first-reset",
		path: `${AUTH_PREFIX_PATH}/first-reset`,
		component: React.lazy(() => import("views/auth-views/authentication/first-time")),
	},
	{
		key: "reset-password",
		path: `${AUTH_PREFIX_PATH}/reset-password`,
		component: React.lazy(() => import("views/auth-views/authentication/reset-password")),
	},
];

export const protectedRoutes = [
	...settingsRoutes,
	...dashboardRoutes,
	...profileRoutes,
	...orgChartRoutes,
	...organizationRoutes,
	...employeeRoutes,
	...complainRoutes,
	...newsRoutes,
	...biBnRoutes,
	...wFourRoutes,
	...goalBipRoutes,
	...warrantyRoutes,
	...meetingRoutes,
	...sopRoutes,
	...stockTakeRoutes,
	...analyticsGISRoutes,
	...analyticsHomeRoutes,
	...analyticsLeaderBoardRoutes,
	...gisSettingsRoutes,
	...b2cRoutes,
	...dealerRoutes,
	...productRoutes,
	...analyticsExternalRoutes,
	...dashboardMGMTRoutes,
	...adminModuleRoutes,
	...analyticsGISRFRoutes,
	...ticketRoutes,
];
