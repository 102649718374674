import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const makeTicketReopen = createAsyncThunk("makeTicketReopen", async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.patch(`desk/ticket/reopen?id=${id}`, data);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const TicketReopenSlice = createSlice({
	name: "TicketReopenSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(makeTicketReopen.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(makeTicketReopen.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(makeTicketReopen.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default TicketReopenSlice.reducer;
