import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const createComplain = createAsyncThunk("createComplain", async ({ data, form, history }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const response = await axiosHttp.post(`complain/create`, data, config);

		form.resetFields();
		message.success("Complain successfully created!");
		history("/app/complains/list");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const complainCreateSlice = createSlice({
	name: "complainCreate",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(createComplain.pending, (state, action) => {
			state.isError = false;
			state.isLoading = true;
		});

		builder.addCase(createComplain.fulfilled, (state, action) => {
			state.isError = false;
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(createComplain.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default complainCreateSlice.reducer;
