import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchCooperateList = createAsyncThunk(
  "fetchCooperateList",
  async () => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.get(
      `customer/corparatecustomer/list`,
      config
    );
    return response.data.data;
  }
);

const cooperateCustomerListSlice = createSlice({
  name: "CooperateCustomerTypeList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCooperateList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCooperateList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchCooperateList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default cooperateCustomerListSlice.reducer;
