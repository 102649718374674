import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const gisSettingsRoutes = [
	{
		key: "gis-settings-qbit",
		exact: true,
		path: `${APP_PREFIX_PATH}/gis/settings/qbit`,
		component: React.lazy(() => import("views/app-views/gis/gis-settings/pages/QbitSetup")),
	},
	{
		key: "gis-settings-sbit",
		exact: true,
		path: `${APP_PREFIX_PATH}/gis/settings/sbit`,
		component: React.lazy(() => import("views/app-views/gis/gis-settings/pages/SbitSetup")),
	},
	{
		key: "gis-settings-mbit",
		exact: true,
		path: `${APP_PREFIX_PATH}/gis/settings/mbit`,
		component: React.lazy(() => import("views/app-views/gis/gis-settings/pages/MbitSetup")),
	},
	{
		key: "gis-map-qbit",
		exact: true,
		path: `${APP_PREFIX_PATH}/gis/map/qbit`,
		component: React.lazy(() => import("views/app-views/gis/map/pages/QbitMap")),
	},
];
