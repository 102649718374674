import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const createLevel = createAsyncThunk(
  "createLevel",
  async ({ data, form, history }) => {
    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
    const response = await axiosHttp.post(
      `level/create`,
      data,
      config
    );

    form.resetFields();
    message.success("Level successfully created!");
    history("/app/employee/level");
    return response.data;
  }
);

const levelCreateSlice = createSlice({
  name: "levelCreate",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(createLevel.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createLevel.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(createLevel.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default levelCreateSlice.reducer;