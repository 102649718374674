import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateStepOrder = createAsyncThunk("updateStepOrder", async ({ data }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};
	const response = await axiosHttp.patch(`/sop/update/sopstepsorder`, data, config);
	message.success("Step Changed!");
	return response.data;
});

const SopStepOrderUpdateSlice = createSlice({
	name: "SopStepOrderUpdateSlice",
	initialState: {
		isLoading: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(updateStepOrder.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(updateStepOrder.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(updateStepOrder.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default SopStepOrderUpdateSlice.reducer;
