
import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const updateSbit = createAsyncThunk(
  "updateSbit",
  async (data) => {

    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        
    const response = await axiosHttp.post(
      `/gis/sbit/update`,
      {
        manager:data.manager,
        code:data.code,
        qbits:data.qbits
      },
      config,
    );

    return response.data;
  }
);

const sbitUpdateSlice = createSlice({
  name: "sbitUpdate",
  initialState: {
    isLoading: false,
    status: null,
    message: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updateSbit.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateSbit.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
    });
    builder.addCase(updateSbit.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default sbitUpdateSlice.reducer;