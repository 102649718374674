import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const analyticsGISRoutes = [
	{
		key: "analytics-gis",
		exact: true,
		path: `${APP_PREFIX_PATH}/analytics/robinHoodMap`,
		component: React.lazy(() => import("views/analytics-views/dashboards/sub-dashboards/internal/gis-map/pages/RobinHoodMap")),
	},
];
