import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant";

export const APP_NAME = "iOrel";

//LIVE 1
// export const API_BASE_URL = "https://iorel-api.orel.com/api/v1/";

//LIVE 2
export const API_BASE_URL = "https://gz31xyjl3g.execute-api.us-east-2.amazonaws.com/iorel/api/v1/";

//LOCAL
// export const API_BASE_URL = "http://localhost:3001/api/v1/";

//DEV
// export const API_BASE_URL = "https://117lv2z493.execute-api.us-east-1.amazonaws.com/api/v1/";

//NEW
// export const API_BASE_URL = "https://tgk5j6si7w.us-east-2.awsapprunner.com/api/v1";

export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const UNAUTHENTICATED_ENTRY = "/login";
export const FIRSTTIME_RESET_ENTRY = "/first-reset";

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: "en",
	navType: NAV_TYPE_SIDE,
	topNavColor: "#3e82f7",
	headerNavColor: "",
	mobileNav: false,
	currentTheme: "dark",
	direction: DIR_LTR,
	blankLayout: false,
};
