import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchCreateBip = createAsyncThunk(
  "fetchCreateBip",
  async ( {data},{rejectWithValue} ) => {
    try{
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await axiosHttp.post(`bip/create`, data, config);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
);

const createBipSlice = createSlice({
  name: "createGoal",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCreateBip.pending, (state, action) => {
      state.isError = false;
      state.isLoading = true;
    });

    builder.addCase(fetchCreateBip.fulfilled, (state, action) => {
      state.isError = false;
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCreateBip.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    });
  },
});

export default createBipSlice.reducer;