import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchIndicatorCategoryList = createAsyncThunk(
  "fetchIndicatorCategoryList",
  async ({page, limit}) => {

    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `vision/category/list?page=${page}&limit=${limit}`,
      config
    );

    return response.data.data;
  }
);

const indicatorCategoryListSlice = createSlice({
  name: "indicatorCategoryList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchIndicatorCategoryList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchIndicatorCategoryList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchIndicatorCategoryList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default indicatorCategoryListSlice.reducer;