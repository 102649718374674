import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateSociety = createAsyncThunk(
  "updateSociety",
  async ({ id, data, form }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.patch(
      `society/update?id=${id}`,
      data,
      config
    );

    form.resetFields();
    message.success("Society successfully updated!");
    return response.data;
  }
);

const societyUpdateSlice = createSlice({
  name: "societyUpdate",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  },
  reducers: {
    clearUpdateStatus: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSociety.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateSociety.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateSociety.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { clearUpdateStatus } = societyUpdateSlice.actions;
export default societyUpdateSlice.reducer;
