import axiosHttp from "services/axiosHttp";
import { fetchDepartmentList } from "./departmentListSlice";
import { fetchDepartmentDetailFind } from "./departmentDetailFindSlice";
import { useSelector } from "react-redux";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDepartmentDetail = createAsyncThunk(
  "fetchDepartmentDetail",
  async ({ id }, ThunkAPI) => {
    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `department/detail?id=${id}`,
      config
    );
    const obj = await ThunkAPI.dispatch(fetchDepartmentDetailFind({ id: response?.data?.data?.parentId }));

    await ThunkAPI.dispatch(fetchDepartmentList({ page: 1, filters: {keyword: obj?.payload?.name} }))
    return response.data.data;
  }
);

const departmentDetailSlice = createSlice({
  name: "departmentDetail",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
 
  reducers: {
    resetStateDepartmentDetail: (state) => {
      state.isLoading = false;
      state.error = null;
      state.data = null;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDepartmentDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchDepartmentDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;

    });
    builder.addCase(fetchDepartmentDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetStateDepartmentDetail } = departmentDetailSlice.actions;
export default departmentDetailSlice.reducer;