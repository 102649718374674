import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchStockTakeDetail = createAsyncThunk(
  "fetchStockTakeDetail",
  async ({ id }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.get(
      `stock-take/detail/${id}`,
      config
    );

    return response.data.data;
  }
);

const stockTakeListSlice = createSlice({
  name: "stockTakeList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStockTakeDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStockTakeDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchStockTakeDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default stockTakeListSlice.reducer;
