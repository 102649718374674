import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchSerialNumberList = createAsyncThunk(
  "fetchSerialNumberList",
  async ({ page, limit, filters }) => {
    const token = localStorage.getItem("auth_token");

    const keyword = filters?.keyword ? filters?.keyword : "";
    const conditionStatus = filters?.conditionStatus
      ? filters?.conditionStatus
      : "";
    const status = filters?.status ? filters?.status : [];
    const warrantyStatusId = filters?.warranty ? filters?.warranty : "";
    const customerTypeId = filters?.customer ? filters?.customer : "";
    const dealerId = filters?.dealer ? filters?.dealer : "";
    const product = filters?.product ? filters?.product : "";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const response = await axiosHttp.get(
      `sn/list?page=${page}&limit=${limit}&keyword=${keyword}&conditionStatusId=${conditionStatus}&statusId=${status}&warrantyStatusId=${warrantyStatusId}&customerTypeId=${customerTypeId}&dealerId=${dealerId}&productId=${product}`,
      {
        status:
          status.length > 0
            ? status.length == 1 && status[0] == ""
              ? []
              : status
            : [],
      },
      config
    );

    return response.data.data;
  }
);

const SerialNumberListSlice = createSlice({
  name: "SerialNumberList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSerialNumberList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSerialNumberList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchSerialNumberList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default SerialNumberListSlice.reducer;
