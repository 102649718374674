import { ORANGE_BASE } from "constants/ThemeConstant";
import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchOrderList = createAsyncThunk("fetchOrderList", async ({ page, limit, filters }) => {
	const token = localStorage.getItem("auth_token");

	const keyword = filters?.keyword ? filters?.keyword : "";
	const customer_type = filters?.customer_type ? filters?.customer_type : "";

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};
	const response = await axiosHttp.get(`order/list?page=${page}&limit=${limit}&keyword=${keyword}&type=${customer_type}`, config);
	return response.data.data;
});

const OrderListSlice = createSlice({
	name: "OrderList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchOrderList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchOrderList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.current_page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchOrderList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default OrderListSlice.reducer;
