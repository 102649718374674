import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchMeetingDetail = createAsyncThunk("fetchMeetingDetail", async ({ id }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};
	const response = await axiosHttp.get(`meeting/detail/${id}`, config);

	return response.data.data;
});

const MeetingDetailSlice = createSlice({
	name: "MeetingDetailSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchMeetingDetail.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMeetingDetail.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchMeetingDetail.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default MeetingDetailSlice.reducer;
