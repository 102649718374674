import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchOrgDepartment = createAsyncThunk("fetchOrgDepartment", async ({ id, type, limit, page }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`bi/department/list?department=${id}&bi=${type == "bi"}&page=${page}&limit=${limit}`, config);

	return response.data.data;
});

const orgDepartmentListSlice = createSlice({
	name: "orgDepartmentList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchOrgDepartment.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchOrgDepartment.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchOrgDepartment.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default orgDepartmentListSlice.reducer;
