import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";


//create action
export const createIndicatorGroup = createAsyncThunk(
  "createIndicatorGroup",
  async ({ data }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Access-Control-Allow-Origin": '*',
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    };
    const response = await axiosHttp.post(
      `vision/value/create`,
      data,
      config
    );
    message.success("Indicator Group successfully created!");
    return response.data;
  }
);

const indicatorGroupCreateSlice = createSlice({
  name: "indicatorGroupCreate",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(createIndicatorGroup.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createIndicatorGroup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(createIndicatorGroup.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default indicatorGroupCreateSlice.reducer;