import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// action
export const orderScan = createAsyncThunk("orderScan", async (serialNo) => {
  try {
    const token = localStorage.getItem("auth_token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    return axiosHttp
      .get(`order/validate?serialNo=${serialNo}`, config)
      .then((data) => {
        return data?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  } catch (error) {
    throw error;
  }
});

const orderScanSlice = createSlice({
  name: "orderScan",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },

  reducers: {
    resetScanState: (state) => {
      state.isLoading = false;
      state.error = false;
      state.data = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(orderScan.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(orderScan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(orderScan.rejected, (state, action) => {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    });
  },
});

export const { resetScanState } = orderScanSlice.actions;
export default orderScanSlice.reducer;
