import axiosHttp from "services/axiosHttp";
import { message } from "antd";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const syncOrders = createAsyncThunk("syncOrders", async () => {
  const token = localStorage.getItem("auth_token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const response = await axiosHttp.post(`order/create`, config);
  message.success("Orders Sync!");
  return response.data.data;
});

const OrderSyncSlice = createSlice({
  name: "OrderSyncSlice",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(syncOrders.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(syncOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(syncOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default OrderSyncSlice.reducer;
