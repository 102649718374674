import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const biBnRoutes = [
	{
		key: "bi-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/todays-challenges/bi/list`,
		component: React.lazy(() => import("views/app-views/burning-issues/pages/BurningIssuesList")),
	},
	{
		key: "bn-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/todays-challenges/bn/list`,
		component: React.lazy(() => import("views/app-views/burning-issues/pages/BottleNeckList")),
	},
	{
		key: "bi-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/todays-challenges/:type/detail/:id`,
		component: React.lazy(() => import("views/app-views/burning-issues/pages/DetailView")),
	},
];
