import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchLeadProjectContacts = createAsyncThunk("fetchLeadProjectContacts", async ({ page, limit }) => {
	const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`category/list?page=${page}&limit=${limit}&category=leadContactType`, config);

	return response.data.data;
});

const LeadProjectContactTypeSlice = createSlice({
	name: "LeadProjectContactTypeSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchLeadProjectContacts.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchLeadProjectContacts.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});
		builder.addCase(fetchLeadProjectContacts.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default LeadProjectContactTypeSlice.reducer;
