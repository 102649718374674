import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchCounts = createAsyncThunk("fetchCounts", async () => {
	const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`${API_BASE_URL}bi/count`, config);

	return response.data.data;
});

const countsSlice = createSlice({
	name: "counts",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchCounts.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchCounts.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchCounts.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default countsSlice.reducer;
