import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateJobStatus = createAsyncThunk(
  "updateJobStatus",
  async ({ id, data }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.patch(
      `job/statusUpdate?id=${id}`,
      data,
      config
    );
    message.success("Updated successfully!");

    return response.data;
  }
);

const JobStatusUpdateSlice = createSlice({
  name: "updateJobStatus",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updateJobStatus.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateJobStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateJobStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default JobStatusUpdateSlice.reducer;
