import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchTicketAdminList = createAsyncThunk("fetchTicketAdminList", async ({ page, limit, filters }) => {
	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		desk: filters?.desk ?? "",
		location: filters?.location ?? "",
		priority: filters?.priority ?? "",
	});

	const response = await axiosHttp.get(`desk/ticket/admin/list?${params}`);
	return response.data.data;
});

const TicketAdminListSlice = createSlice({
	name: "TicketAdminListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchTicketAdminList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchTicketAdminList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.current_page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchTicketAdminList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default TicketAdminListSlice.reducer;
