import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const employeeRoutes = [
	{
		key: "stratum-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/stratum/add`,
		component: React.lazy(() => import("views/app-views/employee/stratum/pages/StratumCreate")),
	},
	{
		key: "stratum-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/stratum/edit/:id`,
		component: React.lazy(() => import("views/app-views/employee/stratum/pages/StratumEdit")),
	},
	{
		key: "level-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/level/add`,
		component: React.lazy(() => import("views/app-views/employee/level/pages/LevelCreate")),
	},
	{
		key: "level-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/level/edit/:id`,
		component: React.lazy(() => import("views/app-views/employee/level/pages/LevelEdit")),
	},
	{
		key: "designation-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/designation/edit/:id`,
		component: React.lazy(() => import("views/app-views/employee/designation/pages/DesignationEdit")),
	},
	{
		key: "designation-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/designation/add`,
		component: React.lazy(() => import("views/app-views/employee/designation/pages/DesignationCreate")),
	},
	{
		key: "employee-bulk-upload",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/create/bulk`,
		component: React.lazy(() => import("views/app-views/employee/list/pages/BulkAdd")),
	},
	{
		key: "stratum",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/stratum`,
		component: React.lazy(() => import("views/app-views/employee/stratum/pages/StratumList")),
	},
	{
		key: "level",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/level`,
		component: React.lazy(() => import("views/app-views/employee/level/pages/LevelList")),
	},
	{
		key: "designation",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/designation`,
		component: React.lazy(() => import("views/app-views/employee/designation/pages/DesignationList")),
	},
	{
		key: "employee",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee`,
		component: React.lazy(() => import("views/app-views/employee/list/pages/EmployeeList")),
	},
	{
		key: "employee-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/detail/:id`,
		component: React.lazy(() => import("views/app-views/employee/list/pages/EmployeeDetail")),
	},
	{
		key: "employee-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/edit/:id`,
		component: React.lazy(() => import("views/app-views/employee/list/pages/EmployeeEdit")),
	},
	{
		key: "employee-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/employee/add`,
		component: React.lazy(() => import("views/app-views/employee/list/pages/EmployeeAdd")),
	},
];
