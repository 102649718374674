import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const analyticsHomeRoutes = [
	{
		key: "analytics-home",
		exact: true,
		path: `${APP_PREFIX_PATH}/analytics/home`,
		component: React.lazy(() => import("views/analytics-views/dashboards/pages/AnalyticHome")),
	},
];
