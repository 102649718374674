import axiosHttp from "services/axiosHttp";
import { message } from "antd";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const sopAttachmentDelete = createAsyncThunk("sopAttachmentDelete", async ({ id }) => {
	const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.patch(`sop/delete/attachment?id=${id}`, config);
	message.success("Your Attachment has been deleted successfully!");
	return response.data;
});

const SopAttachmentDeleteSlice = createSlice({
	name: "SopAttachmentDeleteSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(sopAttachmentDelete.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(sopAttachmentDelete.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(sopAttachmentDelete.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default SopAttachmentDeleteSlice.reducer;
