import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDeskAdminList = createAsyncThunk("fetchDeskAdminList", async () => {
	const response = await axiosHttp.get(`/desk/admin/list?page=1&limit=10000000`);
	return response.data.data;
});

const DeskAdminListSlice = createSlice({
	name: "DeskAdminListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchDeskAdminList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchDeskAdminList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});
		builder.addCase(fetchDeskAdminList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default DeskAdminListSlice.reducer;
