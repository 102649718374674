import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk, createAction } = require("@reduxjs/toolkit");

//action
export const fetchBipList = createAsyncThunk(
  "fetchBipList",
  async ( {id,page,limit,filters} ) => {
   
    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `bip/list?limit=${limit?limit:'1000000000000'}&page=${page?page:1}&goal=${id}`,
      config
    );

    return response.data.data;
  }
);

const initialState = () => ({
    isLoading: false,
    data: null,
    error: null,
});


const bipListSlice = createSlice({
  name: "bipList",
  initialState: initialState(),
  reducers: {
    resetBIPStore : state => initialState()
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBipList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBipList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchBipList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default bipListSlice.reducer;