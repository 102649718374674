import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchModuleList = createAsyncThunk("fetchModuleList", async () => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`module/list`, config);
	
	return response.data.data;
});

const moduleListSlice = createSlice({
	name: "moduleList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	reducers: {
		resetStateModuleList: (state) => {
			state.isLoading = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchModuleList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchModuleList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchModuleList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetStateModuleList } = moduleListSlice.actions;
export default moduleListSlice.reducer;
