import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchCompanyList = createAsyncThunk(
  "fetchCompanyList",
  async ({ page, filters, limit }) => {
    const keyword = filters && filters.keyword !== undefined ? `${filters.keyword}` : "";

    const token = localStorage.getItem("auth_token");
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Access-Control-Allow-Origin": '*',
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    };

    const response = await axiosHttp.get(
      `company/list?page=${page}&limit=${limit}&keyword=${keyword}`,
      config
    );

    return response.data.data;
  }
);

const companyListSlice = createSlice({
  name: "companyList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCompanyList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCompanyList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchCompanyList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default companyListSlice.reducer;