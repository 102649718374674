import React, { useEffect } from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN, REFRESH_TOKEN, USER, PERMISSIONS } from "constants/AuthConstant";
import AuthService from "services/AuthService";
import axiosHttp from "services/axiosHttp";
import { fetchMyDetail } from "./userDetailsSlice";
import { fetchPermission } from "./permissionSlice";
import { initFirebaseNotification } from "./firebaseNotificationSlice";
import PermissionManager from "utils/class/PermissionManager";
import jwt_decode from "jwt-decode";

export const initialState = {
	loading: false,
	message: "",
	temptoken: null,
	showMessage: false,
	redirect: "",
	firstReset: false,
	token: PermissionManager.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk("auth/login", async (data, thunkAPI) => {
	try {
		const response = await axiosHttp.post("auth/login", data);
		const token = response.data.access_token;
		const refreshToken = response.data.refresh_token;
		const decoded = jwt_decode(token);
		if (!decoded.firstReset) {
			PermissionManager.setItem(AUTH_TOKEN, token);
			PermissionManager.setItem(REFRESH_TOKEN, refreshToken);

			await thunkAPI.dispatch(fetchMyDetail(token));
			await thunkAPI.dispatch(fetchPermission(token));
			await thunkAPI.dispatch(initFirebaseNotification(token));

			return {
				token: token,
			};
		}
		return {
			temptoken: token,
			firstReset: true,
		};
	} catch (err) {
		const { rejectWithValue } = thunkAPI;
		return rejectWithValue(err.response?.data?.message || "Error");
	}
});

export const signUp = createAsyncThunk("auth/register", async (data, { rejectWithValue }) => {
	const { email, password } = data;
	try {
		const response = await AuthService.register({ email, password });
		const token = response.data.token;
		PermissionManager.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || "Error");
	}
});

export const signOut = createAsyncThunk("auth/logout", async () => {
	const response = await axiosHttp.get(`auth/logout`);
	PermissionManager.removeItem(AUTH_TOKEN);
	PermissionManager.removeItem(REFRESH_TOKEN);
	PermissionManager.removeItem(USER);
	PermissionManager.removeItem(PERMISSIONS);

	return response.data;
});

export const signInWithGoogle = createAsyncThunk("auth/signInWithGoogle", async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.loginInOAuth();
		const token = response.data.token;
		PermissionManager.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || "Error");
	}
});

export const signInWithFacebook = createAsyncThunk("auth/signInWithFacebook", async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.loginInOAuth();
		const token = response.data.token;
		PermissionManager.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || "Error");
	}
});

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false;
			state.redirect = "/";
			state.token = action.payload;
			state.firstReset = false;
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload;
			state.showMessage = true;
			state.loading = false;
		},
		hideAuthMessage: (state) => {
			state.message = "";
			state.showMessage = false;
		},
		signOutSuccess: (state) => {
			state.loading = false;
			state.token = null;
			state.redirect = "/";
		},
		showLoading: (state) => {
			state.loading = true;
		},
		signInSuccess: (state, action) => {
			state.loading = false;
			state.token = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true;
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false;
				state.token = action.payload.token;
				state.redirect = action.payload.firstReset ? "/auth/first-reset" : "/";
				state.firstReset = action.payload.firstReset;
				state.temptoken = action.payload.temptoken;
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false;
				state.token = null;
				state.redirect = "/";
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false;
				state.token = null;
				state.redirect = "/";
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true;
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = "/";
				state.token = action.payload;
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true;
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = "/";
				state.token = action.payload;
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true;
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = "/";
				state.token = action.payload;
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			});
	},
});

export const { authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, showLoading, signInSuccess } = authSlice.actions;

export default authSlice.reducer;
