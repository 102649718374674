import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const fetchCreateStockTake = createAsyncThunk(
  "fetchCreateStockTake",
  async ({ data, form, history },{rejectWithValue}) => {
    try{
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await axiosHttp.post(`stock-take/create`, data, config);

      form.resetFields();
      message.success("Successfully created!");
      history("/app/stock-take/list");
      return response.data;
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data);
    }
  }
);

const createStockTakeSlice = createSlice({
  name: "createStockTake",
  initialState: {
    isLoading: false,
    error: null,
    data:null
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCreateStockTake.pending, (state, action) => {
      state.isLoading = true;
      
    });

    builder.addCase(fetchCreateStockTake.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    
    builder.addCase(fetchCreateStockTake.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default createStockTakeSlice.reducer;
