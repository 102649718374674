import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const customerCreate = createAsyncThunk(
  "customerCreate",
  async ({ data, form, history }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await axiosHttp.post(`customer/create`, data, config);

      form.resetFields();
      message.success("Customer has been raised successfully!");
      history("/app/warranty/customer/list");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createCustomerSlice = createSlice({
  name: "createCustomer",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
  },

  extraReducers: (builder) => {
    builder.addCase(customerCreate.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(customerCreate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
    });

    builder.addCase(customerCreate.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    });
  },
});

export default createCustomerSlice.reducer;
