import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const b2cRoutes = [
	{
		key: "project-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/b2c/projects-list`,
		component: React.lazy(() => import("views/app-views/b2c/pages/LeadProjectsList")),
	},
	{
		key: "project-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/b2c/projects-create`,
		component: React.lazy(() => import("views/app-views/b2c/pages/LeadProjectCreate")),
	},
	{
		key: "project-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/b2c/project/:id`,
		component: React.lazy(() => import("views/app-views/b2c/pages/LeadProjectDetail")),
	},
];
