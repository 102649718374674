import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDepartmentList = createAsyncThunk("fetchDepartmentList", async ({ page, filters, limit, compLimit = true }) => {
	const keyword = filters && filters.keyword !== undefined ? `${filters.keyword}` : "";
	const company = filters && filters.company !== undefined ? `${filters.company}` : "";
	const vc = filters && filters.valueChain !== undefined ? `${filters.valueChain}` : "";
	const department = filters && filters.parentDepartment !== undefined ? `${filters.parentDepartment}` : "";

	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(
		`department/list?page=${page}&limit=${limit}&keyword=${keyword}&company=${company}&department=${department}&valuechain=${vc}&compLimit=${compLimit}`,
		config
	);

	return response.data.data;
});

const departmentListSlice = createSlice({
	name: "departmentList",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchDepartmentList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchDepartmentList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchDepartmentList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default departmentListSlice.reducer;
