import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchTicketIssueTypes = createAsyncThunk("fetchTicketIssueTypes", async (identifier) => {
	const response = await axiosHttp.get(`category/list?page=1&limit=100000000000&category=Ticket&identifier=${identifier}`);
	return response.data.data;
});

const TicketIssueTypesSlice = createSlice({
	name: "TicketIssueTypesSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchTicketIssueTypes.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchTicketIssueTypes.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});
		builder.addCase(fetchTicketIssueTypes.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default TicketIssueTypesSlice.reducer;
