import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const dealerRoutes = [
	{
		key: "dealer-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/dealer/dealer-list`,
		component: React.lazy(() => import("views/app-views/dealer/pages/DealersList")),
	},
	{
		key: "dealer-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/dealer/:id`,
		component: React.lazy(() => import("views/app-views/dealer/pages/DealerDetail")),
	},
];
