import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateDealer = createAsyncThunk(
  "updateDealer",
  async ({ id, data, form }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.patch(
      `businesspartner/update?Id=${id}`,
      data,
      config
    );

    form.resetFields();
    message.success("Dealer successfully updated!");
    return response.data;
  }
);

const DealerUpdateSlice = createSlice({
  name: "dealerUpdate",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updateDealer.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateDealer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateDealer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default DealerUpdateSlice.reducer;
