import axiosHttp from "../../services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchStatusListAll = createAsyncThunk(
  "fetchStatusListAll",
  async ({identifier}) => {
    const token = localStorage.getItem("auth_token");
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const response = await axiosHttp.get(
      `status/list?identifier=${identifier}`,
      config
    );

    return response.data.data;
  }
);

const statusListAllSlice = createSlice({
  name: "statusListAll",
  initialState: {
    isLoading: false,
    data: null,
    error: null
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStatusListAll.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStatusListAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchStatusListAll.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default statusListAllSlice.reducer;
