import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const stockTakeRoutes = [
	{
		key: "stock-count-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/stock-take/list`,
		component: React.lazy(() => import("views/app-views/stock-take/pages/StockTakeList")),
	},
	{
		key: "stock-count-summary",
		exact: true,
		path: `${APP_PREFIX_PATH}/stock-take/summary`,
		component: React.lazy(() => import("views/app-views/stock-take/pages/StockTakeSummary")),
	},
	{
		key: "stock-count-logs",
		exact: true,
		path: `${APP_PREFIX_PATH}/stock-take/logs`,
		component: React.lazy(() => import("views/app-views/stock-take/pages/StockTakeLogs")),
	},
	{
		key: "stock-count-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/stock-take/add/`,
		component: React.lazy(() => import("views/app-views/stock-take/pages/StockTakeAdd")),
	},
	{
		key: "stock-count-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/stock-take/edit/:id`,
		component: React.lazy(() => import("views/app-views/stock-take/pages/StockTakeEdit")),
	},
	{
		key: "stock-count-product-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/stock-take/product/list`,
		component: React.lazy(() => import("views/app-views/stock-take/pages/StockTakeProductList")),
	},
	{
		key: "stock-count-counter-count",
		exact: true,
		path: `${APP_PREFIX_PATH}/stock-take/counter/count`,
		component: React.lazy(() => import("views/app-views/stock-take/pages/counter/StockTakeCount")),
	},
];
