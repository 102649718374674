import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchApproveComplainList = createAsyncThunk("fetchApproveComplainList", async ({ page, limit, filters }) => {
	const status = filters && filters.status ? `${filters.status}` : "";
	// const company =
	//   filters && filters.company !== undefined ? `${filters.company}` : "";
	const category = filters && filters.category ? `${filters.category}` : "";

	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`complain/adminlist?page=${page}&limit=${limit}&status=${status}&categoryId=${category}`, config);
	return response.data.data;
});

const approveComplainListSlice = createSlice({
	name: "approveComplainDetail",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	reducers: {
		resetStateComplainDetail: (state) => {
			state.isLoading = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchApproveComplainList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchApproveComplainList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchApproveComplainList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetStateComplainDetail } = approveComplainListSlice.actions;
export default approveComplainListSlice.reducer;
