import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const resignEmployee = createAsyncThunk("resignEmployee", async ({ data }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.patch(`employee/resigned`, data, config);
	message.success("Successfully resign the employee");
	return response.data;
});

const employeeResignSlice = createSlice({
	name: "employeeResignSlice",
	initialState: {
		isLoading: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(resignEmployee.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(resignEmployee.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(resignEmployee.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default employeeResignSlice.reducer;
