import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const dashboardMGMTRoutes = [
	{
		key: "sub-dashboard-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/analytics/dashboard-list`,
		component: React.lazy(() => import("views/analytics-views/dashboards-mgmt/pages/SubDashboardList")),
	},
	{
		key: "sub-dashboard-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/subdashboard/:id`,
		component: React.lazy(() => import("views/analytics-views/dashboards-mgmt/pages/SubDashboardDetail")),
	},
	{
		key: "sub-dashboard-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/subdashboard/create`,
		component: React.lazy(() => import("views/analytics-views/dashboards-mgmt/pages/SubDashboardCreate")),
	},
];
