import axiosHttp from "services/axiosHttp";
import { message } from "antd";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const sopDelete = createAsyncThunk("sopDelete", async ({ id }) => {
	const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.patch(`sop/cancel?id=${id}`, config);
	message.success("Your SOP has been deleted successfully!");
	return response.data;
});

const SopDeleteSlice = createSlice({
	name: "SopDeleteSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	reducers: {
		resetDeleteState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(sopDelete.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(sopDelete.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(sopDelete.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { resetDeleteState } = SopDeleteSlice.actions;
export default SopDeleteSlice.reducer;
