import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const analyticsLeaderBoardRoutes = [
	{
		key: "analytics-leaderboard",
		exact: true,
		path: `${APP_PREFIX_PATH}/analytics/leaderboard`,
		component: React.lazy(() => import("views/analytics-views/dashboards/sub-dashboards/internal/leaderboard/pages/AnalyticLeaderboard")),
	},
];
