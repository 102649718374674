import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchSopList = createAsyncThunk("fetchSopList", async ({ page, limit, filters }) => {
	const token = localStorage.getItem("auth_token");
	const keyword = filters?.keyword ? filters?.keyword : "";
	const category = filters?.category ? filters?.category : "";
	const status = filters?.status ? filters?.status : "";
	const companyId = filters?.companyId ? filters?.companyId : "";

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const params = new URLSearchParams({
		page: String(page),
		limit: String(limit),
		keyword: String(keyword),
		category: String(category),
		status: String(status),
		companyId: String(companyId),
	});

	const response = await axiosHttp.get(`sop/list?${params}`, config);
	return response.data.data;
});

const SopListSlice = createSlice({
	name: "SopListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchSopList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchSopList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});
		builder.addCase(fetchSopList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default SopListSlice.reducer;
