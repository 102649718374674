import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

const middlewares = [];

const store = configureStore({
	reducer: rootReducer(), // Make sure rootReducer() is correct. If it's not supposed to be a function call, change it to rootReducer.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === "development",
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(rootReducer(store.asyncReducers)); // Updated from persistReducer to rootReducer
	return store;
};

export default store;
