import { API_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";
import { message } from "antd";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const empLoyeeTransfer = createAsyncThunk("empLoyeeTransfer", async ({ id, departmentId }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.patch(`${API_BASE_URL}employee/transfer?id=${id}&departmentId=${departmentId}`, config);
	message.success("You have successfully transferred the employee.");
	return response.data.data;
});

const orgChartEmployeeTransferSlice = createSlice({
	name: "orgChartEmployeeTransferSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(empLoyeeTransfer.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(empLoyeeTransfer.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(empLoyeeTransfer.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default orgChartEmployeeTransferSlice.reducer;
