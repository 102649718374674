import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const wFourRoutes = [
	{
		key: "wfour-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/wfour/list`,
		component: React.lazy(() => import("views/app-views/w-four/pages/WfourList")),
	},
	{
		key: "wfour-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/wfour/detail/:id`,
		component: React.lazy(() => import("views/app-views/w-four/pages/DetailView")),
	},
];
