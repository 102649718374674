import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateLevel = createAsyncThunk(
  "updateLevel",
  async ({ id, data, form, history }) => {
    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
    const response = await axiosHttp.patch(
      `level/update?id=${id}`,
      data,
      config
    );

    form.resetFields();
    message.success("Level successfully updated!");
    history("/app/employee/level");
    return response.data;
  }
);

const levelUpdateSlice = createSlice({
  name: "levelUpdate",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updateLevel.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateLevel.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateLevel.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default levelUpdateSlice.reducer;