import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const createNews = createAsyncThunk(
  "createNews",
  async ({ data, form, history }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.post(`news/create`, data, config);

    form.resetFields();
    message.success("News successfully created!");
    history("/app/news/list");
    return response.data;
  }
);

const newsCreateSlice = createSlice({
  name: "newsCreate",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(createNews.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createNews.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    
    builder.addCase(createNews.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default newsCreateSlice.reducer;
