import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const updateSubdashboard = createAsyncThunk("updateSubdashboard", async ({ id, data }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const response = await axiosHttp.patch(`analytics/dashboard/update/?id=${id}`, data, config);
		message.success("Dashboard has been Updated successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const SubDashboardUpdateSlice = createSlice({
	name: "SubDashboardUpdateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(updateSubdashboard.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(updateSubdashboard.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(updateSubdashboard.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default SubDashboardUpdateSlice.reducer;
