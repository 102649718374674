
import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const updateQbitCode = createAsyncThunk(
  "updateQbitCode",
  async (data) => {

    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };
        
    const response = await axiosHttp.post(
      `/gis/qbit/update?id=${data.id}`,
      {
        code:data.code,
        assistant:data.assistant,
        deputy:data.deputy,
        leader:data.leader,
      },
      config,
    );

    return response.data;
  }
);

const qbitUpdateSlice = createSlice({
  name: "qbitUpdate",
  initialState: {
    isLoading: false,
    status: null,
    message: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updateQbitCode.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateQbitCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.status = action.payload.status;
      state.message = action.payload.message;
    });
    builder.addCase(updateQbitCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default qbitUpdateSlice.reducer;