import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchEditBip = createAsyncThunk("fetchEditBip", async ({ id, data }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const response = await axiosHttp.post(`bip/update/${id}`, data, config);

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const updateBipSlice = createSlice({
	name: "editBipSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
	},

	reducers: {
		resetEditBIPState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchEditBip.pending, (state, action) => {
			state.isError = false;
			state.isLoading = true;
		});

		builder.addCase(fetchEditBip.fulfilled, (state, action) => {
			state.isError = false;
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchEditBip.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetEditBIPState } = updateBipSlice.actions;
export default updateBipSlice.reducer;
