
import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDesignationList = createAsyncThunk(
  "fetchDesignationList",
  async ({ page, filters, limit }) => {
    const keyword = filters && filters.keyword !== undefined ? `${filters.keyword}` : "";
    const company = filters && filters.company !== undefined ? `${filters.company}` : "";
    const stratum = filters && filters.stratum !== undefined ? `${filters.stratum}` : "";

    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `designation/list?page=${page}&limit=${limit}&keyword=${keyword}&stratum=${stratum}&company=${company}`,
      config
    );

    return response.data.data;
  }
);

const designationListSlice = createSlice({
  name: "designationList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDesignationList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchDesignationList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchDesignationList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default designationListSlice.reducer;