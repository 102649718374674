import axiosHttp from "services/axiosHttp";
import { fetchCompanyList } from "./companyListSlice";
import { fetchCompanyDetailFind } from "./companyDetailFindSlice";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchCompanyDetail = createAsyncThunk(
  "fetchCompanyDetail",
  async ({ id }, ThunkAPI) => {
    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `company/detail/${id}`,
      config
    );
    const obj = await ThunkAPI.dispatch(fetchCompanyDetailFind({ id: response?.data?.data?.parentId }));
    await ThunkAPI.dispatch(fetchCompanyList({ page: 1, filters: {keyword: obj?.payload?.name} }))
    return response.data.data;
  }
);

const companyDetailSlice = createSlice({
  name: "companyDetail",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
 
  reducers: {
    resetStateCompanyDetail: (state) => {
      state.isLoading = false;
      state.error = null;
      state.data = null;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCompanyDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCompanyDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;

    });
    builder.addCase(fetchCompanyDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetStateCompanyDetail } = companyDetailSlice.actions;
export default companyDetailSlice.reducer;