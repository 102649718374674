import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const productRoutes = [
	{
		key: "product-category-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/product/categories/product-category-list`,
		component: React.lazy(() => import("views/app-views/product/categories/pages/ProductCategoryList")),
	},
	{
		key: "create-product-category",
		exact: true,
		path: `${APP_PREFIX_PATH}/product/categories/product-category-create`,
		component: React.lazy(() => import("views/app-views/product/categories/pages/CreateProductCategoryView")),
	},
	{
		key: "edit-product-category",
		exact: true,
		path: `${APP_PREFIX_PATH}/product/categories/product-category-edit/:id`,
		component: React.lazy(() => import("views/app-views/product/categories/pages/EditProductCategoryView")),
	},
	{
		key: "-product-category-tree",
		exact: true,
		path: `${APP_PREFIX_PATH}/product/categories/product-category-tree`,
		component: React.lazy(() => import("views/app-views/product/categories/pages/ProductCategoryTree")),
	},
];
