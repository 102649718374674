import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchOrderDetail = createAsyncThunk(
  "fetchOrderDetail",
  async ({ id }) => {
    const token = localStorage.getItem("auth_token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.get(`order/detail/?id=${id}`, config);
    return response.data.data;
  }
);

const OrderDetailSlice = createSlice({
  name: "OrderDetailSlice",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchOrderDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchOrderDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default OrderDetailSlice.reducer;
