import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchStockTakeProductDetail = createAsyncThunk(
  "fetchStockTakeProductDetail",
  async ({ id,type,productCode,stockCountId,subLocationCode,locationCode }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const response = await axiosHttp.get(
      `stock-take/product/detail?id=${id}&type=${type}&productCode=${productCode}&stockCountId=${stockCountId}&subLocationCode=${subLocationCode}&locationCode=${locationCode}`,
      config
    );

    return response.data;
  }
);

const stockTakeProductDetailSlice = createSlice({
  name: "stockTakeProductDetail",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStockTakeProductDetail.pending, (state, action) => {
      state.isLoading = true;
      state.data = null;
    });

    builder.addCase(fetchStockTakeProductDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchStockTakeProductDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = null;
    });
  },
});

export default stockTakeProductDetailSlice.reducer;
