import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const updateMeeting = createAsyncThunk("updateMeeting", async ({ id, data }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const response = await axiosHttp.patch(`meeting/${id}`, data, config);
		message.success("Your Meeting has been updated successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const MeetingUpdateSlice = createSlice({
	name: "MeetingUpdateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(updateMeeting.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(updateMeeting.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(updateMeeting.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default MeetingUpdateSlice.reducer;
