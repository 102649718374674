import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateWFourStatus = createAsyncThunk(
  "updateWFourStatus",
  async ({ id, data }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.patch(`wfour/updateStatus?id=${id}`,data,config);
    message.success("Updated successfully!");
    return response.data;
  }
);

const WfourUpdateStatusSlice = createSlice({
  name: "updateWFourStatus",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updateWFourStatus.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateWFourStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(updateWFourStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default WfourUpdateStatusSlice.reducer;
