import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const settingsRoutes = [
	{
		key: "settings",
		exact: true,
		path: `${APP_PREFIX_PATH}/settings/*`,
		component: React.lazy(() => import("views/app-views/settings/pages/index")),
	},
];
