import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchEmployeeDetail = createAsyncThunk(
  "fetchEmployeeDetail",
  async ({ id }) => {
    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `employee/detail?id=${id}`,
      config
    );
    
    return response.data.data;
  }
);

const employeeDetailSlice = createSlice({
  name: "employeeDetail",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
 
  reducers: {
    resetStateEmployeeDetail: (state) => {
      state.isLoading = false;
      state.error = null;
      state.data = null;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchEmployeeDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchEmployeeDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;

    });
    builder.addCase(fetchEmployeeDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetStateEmployeeDetail } = employeeDetailSlice.actions;
export default employeeDetailSlice.reducer;