import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { AUTHENTICATED_ENTRY } from "configs/AppConfig";

const PublicRoute = () => {
	const { token } = useSelector((state) => state.auth);

	if (token) {
		return (window.location.href = AUTHENTICATED_ENTRY);
	}
	return <Outlet />;
};

export default PublicRoute;
