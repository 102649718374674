import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";

//create action
export const sendEmail = createAsyncThunk("sendEmail", async ({email},{ rejectWithValue }) => {
  try {
    const response = await axios.post(`${API_BASE_URL}auth/forgotpassword`, {email});
    return response.data;
  } catch (error) {
    message.error(error?.message);
    return rejectWithValue(error.response.data);
  }
});

const sendEmailSlice = createSlice({
  name: "sendEmailSlice",
  initialState: {
    isLoading: false,
    error: null,
    isError:false,
    isSuccess:false,
  },

  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
    });

    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
      state.isSuccess = true;
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isError = true;
      state.isSuccess = false;
    });
  },
});

export default sendEmailSlice.reducer;
