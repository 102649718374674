import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//comment create slice
export const createComplainComment = createAsyncThunk("createComplainComment", async ({ id, content }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};
	try {
		const body = {
			content: content,
		};
		const response = await axiosHttp.post(`complain/comment/create?id=${id}`, body, config);
		message.success("Complain successfully commented!");
		return response.data;
	} catch (error) {
		message.error(error.response.data.message);
	}
});

const complainCommentCreateSlice = createSlice({
	name: "complainCreate",
	initialState: {
		isLoading: false,
		data: {},
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(createComplainComment.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(createComplainComment.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(createComplainComment.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default complainCommentCreateSlice.reducer;
