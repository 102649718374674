import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const complainRoutes = [
	{
		key: "complain-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/complains/list`,
		component: React.lazy(() => import("views/app-views/complains/pages/ComplainsList")),
	},
	{
		key: "complain-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/complains/add`,
		component: React.lazy(() => import("views/app-views/complains/pages/ComplainAdd")),
	},
	{
		key: "complains-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/complains/detail/:id`,
		component: React.lazy(() => import("views/app-views/complains/pages/ComplainDetail")),
	},
	{
		key: "complains-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/complains/edit/:id`,
		component: React.lazy(() => import("views/app-views/complains/pages/ComplainEdit")),
	},
	{
		key: "complains-approve",
		exact: true,
		path: `${APP_PREFIX_PATH}/complains/approve`,
		component: React.lazy(() => import("views/app-views/complains/pages/ApproveComplainsList")),
	},
];
