import axiosHttp from "services/axiosHttp";
import { message } from "antd";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchEmployeePasswordReset = createAsyncThunk("fetchEmployeePasswordReset", async ({ id }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.patch(`auth/admin/resetpassword?id=${id}`, config);
	message.success("Successfully reset the password to its default value");
	return response.data.data;
});

const employeePasswordResetSlice = createSlice({
	name: "employeePasswordResetSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchEmployeePasswordReset.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchEmployeePasswordReset.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchEmployeePasswordReset.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default employeePasswordResetSlice.reducer;
