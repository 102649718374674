import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";
import moment from "moment";

//CREATE ACTION
export const createMeeting = createAsyncThunk("createMeeting", async ({ data }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");
		const getTimezoneOffsetInMinutes = () => moment().utcOffset();

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
				tz: getTimezoneOffsetInMinutes(),
				"Timezone-Offset": getTimezoneOffsetInMinutes(),
			},
		};
		const response = await axiosHttp.post(`meeting/create`, data, config);
		message.success("Your Meeting has been created successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const MeetingsCreateSlice = createSlice({
	name: "MeetingsCreateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(createMeeting.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(createMeeting.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(createMeeting.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default MeetingsCreateSlice.reducer;
