import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchStockTakeList = createAsyncThunk(
  "fetchStockTakeList",
  async ({ page, limit,filters }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const keyword      = filters?.keyword?filters?.keyword:"";

    const response = await axiosHttp.get(
      `stock-take/list?page=${page}&limit=${limit}&keyword=${keyword}`,
      config
    );

    return response.data.data;
  }
);

const stockTakeListSlice = createSlice({
  name: "stockTakeList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStockTakeList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStockTakeList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchStockTakeList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default stockTakeListSlice.reducer;
