import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const modelCreate = createAsyncThunk(
  "modelCreate",
  async ({ data, form }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await axiosHttp.post(
        `product/model/create`,
        data,
        config
      );

      form.resetFields();
      message.success("Model has been created successfully!");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const CreateModelSlice = createSlice({
  name: "CreateModelSlice",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
  },

  extraReducers: (builder) => {
    builder.addCase(modelCreate.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(modelCreate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
    });

    builder.addCase(modelCreate.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    });
  },
});

export default CreateModelSlice.reducer;
