import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const goalBipRoutes = [
	{
		key: "goals",
		exact: true,
		path: `${APP_PREFIX_PATH}/goals/tree/:id`,
		component: React.lazy(() => import("views/app-views/goals/pages/GoalTree")),
	},
	{
		key: "goals-parent",
		exact: true,
		path: `${APP_PREFIX_PATH}/goals/tree`,
		component: React.lazy(() => import("views/app-views/goals/pages/GoalTree")),
	},
	{
		key: "bip-parent",
		exact: true,
		path: `${APP_PREFIX_PATH}/bip/:id`,
		component: React.lazy(() => import("views/app-views/goals/pages/BipTree")),
	},
	{
		key: "bip-parent",
		exact: true,
		path: `${APP_PREFIX_PATH}/bip-list`,
		component: React.lazy(() => import("views/app-views/goals/pages/BipList")),
	},
];
