// initializeFirebaseMessaging.js

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { notification } from "antd";
import { FCM_TOKEN } from "constants/AuthConstant";
import firebaseConfig from "configs/FirebaseConfig";
import "./notificationStyles.css";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export async function initializeFirebaseMessaging() {
	try {
		const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FCM_VAPID_KEY });
		if (currentToken) {
			localStorage.setItem(FCM_TOKEN, currentToken);
		} else {
			console.error("No registration token available. Request permission to generate one.");
		}
	} catch (err) {
		console.error("An error occurred while retrieving token:", err);
		if (err.code === "messaging/permission-blocked") {
			console.error("Permission to send notifications is blocked by the user.");
		} else if (err.code === "messaging/permission-default") {
			console.error("The user has not granted permission to send notifications.");
		} else {
			console.error("Unable to retrieve FCM token. Please check network connection or Firebase configuration.");
		}
	}

	onMessage(messaging, (payload) => {
		const notificationContent = payload?.notification;
		const notificationData = payload?.data;
		notification.info({
			message: notificationContent.title,
			description: notificationContent.body,
			duration: 100,
			className: "custom-notification",
			onClick: () => {
				window.location.href = `${notificationData?.path}${notificationData?.id}`;
			},
		});
	});
}
