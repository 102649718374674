import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchComplainLimitCheck = createAsyncThunk("fetchComplainLimitCheck", async () => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`complain/cooldown/limit`, config);

	return response.data;
});

const complainLimitSlice = createSlice({
	name: "complainLimitCheck",
	initialState: {
		isLoading: false,
		data: { create: true },
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchComplainLimitCheck.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchComplainLimitCheck.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchComplainLimitCheck.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default complainLimitSlice.reducer;
