import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateEmployee = createAsyncThunk("updateEmployee", async ({ id, data, form, history }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.patch(`employee/update?id=${id}`, data, config);

	form.resetFields();
	message.success("Employee successfully updated!");
	history("/app/employee");
	return response.data;
});

const employeeUpdateSlice = createSlice({
	name: "employeeUpdate",
	initialState: {
		isLoading: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(updateEmployee.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(updateEmployee.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(updateEmployee.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default employeeUpdateSlice.reducer;
