import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const productCategoryCreate = createAsyncThunk("productCategoryCreate", async ({ data }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const response = await axiosHttp.post(`/productcategory/create`, data, config);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const ProductCategoryCreateSlice = createSlice({
	name: "ProductCategoryCreateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	reducers: {
		resetCreateState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(productCategoryCreate.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(productCategoryCreate.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(productCategoryCreate.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetCreateState } = ProductCategoryCreateSlice.actions;
export default ProductCategoryCreateSlice.reducer;
