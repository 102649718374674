import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchStockSummaryList = createAsyncThunk(
  "fetchStockSummaryList",
  async ({ page, limit,filters }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const productCode      = filters?.productCode?filters?.productCode:"";
    const stockCountId      = filters?.stockCountId?filters?.stockCountId:"";
    const subLocationCode      = filters?.subLocationCode?filters?.subLocationCode:"";
    const locationCode      = filters?.locationCode?filters?.locationCode:"";

    const response = await axiosHttp.get(
      `stock-take/summary?page=${page}&limit=${limit}&productCode=${productCode}&stockCountId=${stockCountId}&subLocationCode=${subLocationCode}&locationCode=${locationCode}`,
      config
    );

    return response.data.data;
  }
);

const stockSummaryListSlice = createSlice({
  name: "stockSummaryList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStockSummaryList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStockSummaryList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchStockSummaryList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default stockSummaryListSlice.reducer;
