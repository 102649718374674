import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchLeadPotentialProducts = createAsyncThunk("fetchLeadPotentialProducts", async () => {
	const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`leads/list/potential?categoryType=Lead product type`, config);

	return response.data.data;
});

const LeadPotentialProductSlice = createSlice({
	name: "LeadPotentialProductSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchLeadPotentialProducts.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchLeadPotentialProducts.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchLeadPotentialProducts.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default LeadPotentialProductSlice.reducer;
