import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const updateMeetingMinute = createAsyncThunk("updateMeetingMinute", async ({ minuteId, data }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const response = await axiosHttp.patch(`meeting/${minuteId}/minute`, data, config);
		message.success("Your Meeting has been updated successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const MeetingMinutesUpdateSlice = createSlice({
	name: "MeetingMinutesUpdateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(updateMeetingMinute.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(updateMeetingMinute.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(updateMeetingMinute.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default MeetingMinutesUpdateSlice.reducer;
