import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

/**
 * This Slice used for get all product with pagination for table actions
 */

export const fetchProductsList = createAsyncThunk(
  "fetchProductsList",
  async ({ page, limit, filters }) => {
    const token = localStorage.getItem("auth_token");

    const keyword = filters?.keyword ? filters?.keyword : "";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const response = await axiosHttp.get(
      `product/list?page=${page}&limit=${limit}&keyword=${keyword}`,
      config
    );

    return response.data;
  }
);

const ProductListSlice = createSlice({
  name: "ProductListSlice",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchProductsList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchProductsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.current_page;
      state.total = action.payload.count;
    });
    builder.addCase(fetchProductsList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default ProductListSlice.reducer;
