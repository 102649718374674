import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchStockTakeLogList = createAsyncThunk(
  "fetchStockTakeLogList",
  async ({ page, limit,filters }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const keyword      = filters?.keyword?filters?.keyword:"";
    const productId      = filters?.productId?filters?.productId:"";
    const subLocationId      = filters?.subLocationId?filters?.subLocationId:"";
    const stockCountId      = filters?.stockCountId?filters?.stockCountId:"";
    const created_by      = filters?.created_by?filters?.created_by:"";

    const response = await axiosHttp.get(
      `stock-take/log?page=${page}&limit=${limit}&keyword=${keyword}&productId=${productId}&subLocationId=${subLocationId}&stockCountId=${stockCountId}&created_by=${created_by}`,
      config
    );

    return response.data.data;
  }
);

const stockTakeLogListSlice = createSlice({
  name: "stockTakeLogList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStockTakeLogList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStockTakeLogList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchStockTakeLogList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default stockTakeLogListSlice.reducer;
