import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchMyContribution = createAsyncThunk("fetchMyContribution", async () => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`analytics/myContribution`, config);

	return response.data;
});

const myContributionSlice = createSlice({
	name: "myContributionSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	reducers: {
		resetMyContributionSliceState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchMyContribution.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchMyContribution.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});

		builder.addCase(fetchMyContribution.rejected, (state, action) => {
			state.isLoading = false;
			state.data = null;
			state.error = action.payload;
		});
	},
});

export const { resetMyContributionSliceState } = myContributionSlice.actions;
export default myContributionSlice.reducer;
