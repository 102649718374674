import axiosHttp from "services/axiosHttp";
import { message } from "antd";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const deleteProject = createAsyncThunk("deleteProject", async ({ id }) => {
	const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.patch(`leads/delete?id=${id}`, config);
	message.success("Project has been deleted successfully!");
	return response.data.data;
});

const ProjectDeleteSlice = createSlice({
	name: "ProjectDeleteSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(deleteProject.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(deleteProject.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(deleteProject.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default ProjectDeleteSlice.reducer;
