import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchNewsDetail = createAsyncThunk(
  "fetchNewsDetail",
  async ({ id }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const response = await axiosHttp.get(`news/detail/?id=${id}`, config);

    return response.data.data;
  }
);

const newsDetailSlice = createSlice({
  name: "newsDetail",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },

  reducers: {
    resetStateNewsDetail: (state) => {
      state.isLoading = false;
      state.error = null;
      state.data = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchNewsDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchNewsDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchNewsDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetStateNewsDetail } = newsDetailSlice.actions;
export default newsDetailSlice.reducer;
