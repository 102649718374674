import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const fetchUpdateStockTake = createAsyncThunk(
  "fetchUpdateStockTake",
  async ({ id, data, form, history }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axiosHttp.patch(
      `stock-take/update/${id}`,
      data,
      config
    );

    form.resetFields();
    message.success("Successfully updated!");
    history("/app/stock-take/list");
    return response.data;
  }
);

const updateStockTakeSlice = createSlice({
  name: "updateStockTake",
  initialState: {
    isLoading: false,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUpdateStockTake.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUpdateStockTake.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUpdateStockTake.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default updateStockTakeSlice.reducer;
