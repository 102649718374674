import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const uploadEmployeeList = createAsyncThunk("uploadEmployeeList", async ({ file }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const formData = new FormData();
		formData.append("file", file);
		const response = await axiosHttp.post(`employee/bulk/upload`, formData, config);
		message.success("Employee list has been raised successfully!");
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const uploadEmployeelistSlice = createSlice({
	name: "uploadEmployeelistSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	reducers: {
		resetEmployeeUpload: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(uploadEmployeeList.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(uploadEmployeeList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(uploadEmployeeList.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default uploadEmployeelistSlice.reducer;
export const { resetEmployeeUpload } = uploadEmployeelistSlice.actions;
