import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

/**
 * This Slice used for get all dealer with pagination for Table views
 */

export const fetchDealersList = createAsyncThunk(
  "fetchDealersList",
  async ({ page, limit, filters }) => {
    const token = localStorage.getItem("auth_token");

    const keyword = filters?.keyword ? filters?.keyword : "";

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const response = await axiosHttp.get(
      `businesspartner/list?page=${page}&limit=${limit}`,
      config
    );

    return response.data;
  }
);

const dealersListSlice = createSlice({
  name: "dealersList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDealersList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchDealersList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.current_page;
      state.total = action.payload.count;
    });
    builder.addCase(fetchDealersList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default dealersListSlice.reducer;
