import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const dashboardRoutes = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboards/default`,
		component: React.lazy(() => import("views/app-views/dashboards/default")),
	},
];
