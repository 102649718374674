import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const jobCreate = createAsyncThunk(
  "jobCreate",
  async ({ data, form, history }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await axiosHttp.post(`job/create`, data, config);

      form.resetFields();
      message.success("Job has been raised successfully!");
      history("/app/warranty/job/list");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const createJobSlice = createSlice({
  name: "createJob",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
  },

  reducers: {
    resetState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.data = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(jobCreate.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(jobCreate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
    });

    builder.addCase(jobCreate.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
      state.data = null;
    });
  },
});

export const { resetState } = createJobSlice.actions;
export default createJobSlice.reducer;
