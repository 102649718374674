import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const adminModuleRoutes = [
	{
		key: "permission-manage",
		exact: true,
		path: `${APP_PREFIX_PATH}/admin/permission/manage`,
		component: React.lazy(() => import("views/admin-views/permission/pages/permssion-manage")),
	}
];
