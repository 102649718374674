import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchTicketList = createAsyncThunk("fetchTicketList", async ({ page, limit, filters }) => {
	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		desk: filters?.desk ?? "",
	});

	const response = await axiosHttp.get(`desk/ticket/list?${params}`);
	return response.data.data;
});

const TicketListSlice = createSlice({
	name: "TicketListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchTicketList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchTicketList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.current_page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchTicketList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default TicketListSlice.reducer;
