import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchBiCancel = createAsyncThunk("fetchBiCancel", async ({ id }) => {
	const token = localStorage.getItem("auth_token");

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`bi/cancel?id=${id}`, config);
	return response.data;
});

const biCancelSlice = createSlice({
	name: "biCancel",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	reducers: {
		BiCancelResetState: (state) => {
			state.isLoading = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchBiCancel.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchBiCancel.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchBiCancel.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export const { BiCancelResetState } = biCancelSlice.actions;
export default biCancelSlice.reducer;
