import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const organizationRoutes = [
	{
		key: "company-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/company/add`,
		component: React.lazy(() => import("views/app-views/organization/company/pages/CompanyAdd")),
	},
	{
		key: "company-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/company/edit/:id`,
		component: React.lazy(() => import("views/app-views/organization/company/pages/CompanyEdit")),
	},
	{
		key: "location-form",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/location/form`,
		component: React.lazy(() => import("views/app-views/organization/location/components/LocationComponent")),
	},
	{
		key: "company",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/company`,
		component: React.lazy(() => import("views/app-views/organization/company/pages/CompanyList")),
	},
	{
		key: "location",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/location`,
		component: React.lazy(() => import("views/app-views/organization/location/pages/LocationList")),
	},
	{
		key: "location-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/location/add`,
		component: React.lazy(() => import("views/app-views/organization/location/pages/LocationAdd")),
	},
	{
		key: "location-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/location/edit/:id`,
		component: React.lazy(() => import("views/app-views/organization/location/pages/LocationEdit")),
	},
	{
		key: "value-chain",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/value-chain`,
		component: React.lazy(() => import("views/app-views/organization/value-chain/pages/ValueChainList")),
	},
	{
		key: "value-chain-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/value-chain/add`,
		component: React.lazy(() => import("views/app-views/organization/value-chain/pages/ValueChainAdd")),
	},
	{
		key: "value-chain-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/value-chain/edit/:id`,
		component: React.lazy(() => import("views/app-views/organization/value-chain/pages/ValueChainEdit")),
	},
	{
		key: "department",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/department`,
		component: React.lazy(() => import("views/app-views/organization/department/pages/DepartmentList")),
	},
	{
		key: "department-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/department/add`,
		component: React.lazy(() => import("views/app-views/organization/department/pages/DepartmentAdd")),
	},
	{
		key: "department-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/organization/department/edit/:id`,
		component: React.lazy(() => import("views/app-views/organization/department/pages/DepartmentEdit")),
	},
];
