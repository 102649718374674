import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchSocietyList = createAsyncThunk(
  "fetchSocietyList",
  async ({ page, filters, limit }) => {
    const keyword = filters && filters.keyword !== undefined ? `${filters.keyword}` : "";
    const company = filters && filters.company !== undefined ? `${filters.company}` : "";

    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `society/list?page=${page}&limit=${limit}&keyword=${keyword}&company=${company}`,
      config
    );

    return response.data.data;
  }
);

const societyListSlice = createSlice({
  name: "societyList",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSocietyList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSocietyList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchSocietyList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default societyListSlice.reducer;