import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchGISUserList = createAsyncThunk("fetchGISUserList", async () => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};

	const response = await axiosHttp.get(`/gis/user/location/list`, config);

	return response.data.data;
});

const gisUserLocationListSlice = createSlice({
	name: "gisUserLocationListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchGISUserList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchGISUserList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchGISUserList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default gisUserLocationListSlice.reducer;
