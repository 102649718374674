import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchConfigurationList = createAsyncThunk(
  "fetchConfigurationList",
  async () => {

    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `vision/`,
      config
    );

    return response.data.data;
  }
);

const configurationListSlice = createSlice({
  name: "configurationList",
  initialState: {
    isLoading: false,
    data: [],
    error: null,
    page: 1,
    total: 0,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchConfigurationList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchConfigurationList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchConfigurationList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default configurationListSlice.reducer;