import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchStockHistory = createAsyncThunk(
  "fetchStockHistory",
  async ({ location,subLocation,type,stockTake,productCode }) => {
    const token = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const pCode = productCode!=null?productCode:"";
    
    const response = await axiosHttp.get(
      `stock-take/user/log?stockCountId=${stockTake}&productCode=${pCode}&limit=10000000000000000&page=1`,
      config
    );

    return response.data.data;
  }
);

const StockHistorySlice = createSlice({
  name: "StockHistorySlice",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    page: 1,
    total: 0,
  },

  reducers: {
		resetStockHistory: (state) => {
			state.isLoading = false;
			state.error = null;
			state.data = null;
		},
	},


  extraReducers: (builder) => {
    builder.addCase(fetchStockHistory.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStockHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.page = action.payload.page;
      state.total = action.payload.total;
    });
    builder.addCase(fetchStockHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { resetStockHistory } = StockHistorySlice.actions;
export default StockHistorySlice.reducer;
