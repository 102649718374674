import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchBipDetail = createAsyncThunk(
  "fetchBipDetail",
  async ( {id} ) => {
   
    const token = localStorage.getItem("auth_token");
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Access-Control-Allow-Origin": '*',
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        };

    const response = await axiosHttp.get(
      `bip/detail?id=${id}`,
      config
    );

    return response.data.data;
  }
);

const bipDetailSlice = createSlice({
  name: "bipDetail",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchBipDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchBipDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchBipDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default bipDetailSlice.reducer;