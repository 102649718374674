import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

//create action
export const updateComplainStatus = createAsyncThunk("updateStatus", async ({ id, status, history }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};
	const response = await axiosHttp.patch(`complain/status?id=${id}`, { status: status }, config);

	message.success("successfully updated!");
	// history("/app/complains/approve");
	return response.data;
});

const complainStatusUpdateSlice = createSlice({
	name: "newsUpdate",
	initialState: {
		isLoading: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(updateComplainStatus.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(updateComplainStatus.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(updateComplainStatus.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default complainStatusUpdateSlice.reducer;
