import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";

//create action
export const resetPassword = createAsyncThunk("resetPassword", async ({ token, password, confirmPassword }, { rejectWithValue }) => {
	try {
		const response = await axios.patch(`${API_BASE_URL}auth/resetpassword/${token}`, {
			password: password,
			passwordConfirm: confirmPassword,
		});

		if (response.status === 200) {
			window.location.href = "/";
		}
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const resetPasswordSlice = createSlice({
	name: "resetPasswordSlice",
	initialState: {
		isLoading: false,
		error: null,
		isError: false,
	},

	extraReducers: (builder) => {
		builder.addCase(resetPassword.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(resetPassword.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.isError = false;
		});
		builder.addCase(resetPassword.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
			state.isError = true;
		});
	},
});

export default resetPasswordSlice.reducer;
