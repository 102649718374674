import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchUpdateBipDone = createAsyncThunk("fetchUpdateBipDone", async ({ id }, { rejectWithValue }) => {
	try {
		const token = localStorage.getItem("auth_token");
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
		const response = await axiosHttp.get(`bip/done/${id}`, config);

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const updateBipDoneSlice = createSlice({
	name: "updateBipDone",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchUpdateBipDone.pending, (state, action) => {
			state.isError = false;
			state.isLoading = true;
		});

		builder.addCase(fetchUpdateBipDone.fulfilled, (state, action) => {
			state.isError = false;
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchUpdateBipDone.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default updateBipDoneSlice.reducer;
