import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const analyticsGISRFRoutes = [
	{
		key: "analytics-gis-rf-map",
		exact: true,
		path: `${APP_PREFIX_PATH}/analytics/rf/robinHoodMap`,
		component: React.lazy(() => import("views/analytics-views/dashboards/sub-dashboards/internal/gis-rf-map/pages/RobinHoodMapRF")),
	},
];
