import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosHttp from "services/axiosHttp";

export const uploadSNListWarehouseIn = createAsyncThunk(
  "uploadSNListWarehouseIn",
  async ({ file }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const formData = new FormData();
      formData.append("file", file);
      const response = await axiosHttp.post(
        `sn/bulkwarehousein/upload`,
        formData,
        config
      );
      message.success("Serial Number list has been raised successfully!");
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const uploadSNListWarehouseInSlice = createSlice({
  name: "uploadSNListWarehouseInSlice",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    data: null,
  },

  reducers: {
    resetBulkUploadState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.data = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(uploadSNListWarehouseIn.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
    });

    builder.addCase(uploadSNListWarehouseIn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
    });

    builder.addCase(uploadSNListWarehouseIn.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
      state.data = null;
    });
  },
});
export const { resetBulkUploadState } = uploadSNListWarehouseInSlice.actions;
export default uploadSNListWarehouseInSlice.reducer;
