import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchProductCategoryDetail = createAsyncThunk("fetchProductCategoryDetail", async ({ id }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};
	const response = await axiosHttp.get(`productcategory/detail?id=${id}`, config);
	return response.data.data;
});

const ProductCategoryDetailSlice = createSlice({
	name: "ProductCategoryDetailSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProductCategoryDetail.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchProductCategoryDetail.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchProductCategoryDetail.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default ProductCategoryDetailSlice.reducer;
