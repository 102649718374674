import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDeskUsers = createAsyncThunk("fetchDeskUsers", async (id) => {
	const response = await axiosHttp.get(`desk/users?id=${id}`);
	return response.data.data;
});

const DeskUsersListSlice = createSlice({
	name: "DeskUsersListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchDeskUsers.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchDeskUsers.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.current_page;
			state.total = action.payload.total;
		});
		builder.addCase(fetchDeskUsers.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default DeskUsersListSlice.reducer;
