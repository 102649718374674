import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchDealerDetail = createAsyncThunk("fetchDealerDetail", async ({ id }) => {
	const token = localStorage.getItem("auth_token");
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	};
	const response = await axiosHttp.get(`dealer/${id}`, config);

	return response.data.data;
});

const DealerDetailSlice = createSlice({
	name: "DealerDetailSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDealerDetail.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchDealerDetail.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchDealerDetail.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default DealerDetailSlice.reducer;
