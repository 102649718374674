import qs from "qs";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { REFRESH_TOKEN, AUTH_TOKEN, USER } from "constants/AuthConstant";
import PermissionManager from "utils/class/PermissionManager";
import moment from "moment";

// Get refresh token
function getRefreshToken() {
	if (typeof window !== "undefined") {
		return PermissionManager.getItem(REFRESH_TOKEN);
	}
}

// Save user tokens
function saveUser(data) {
	if (typeof window !== "undefined") {
		PermissionManager.setItem(AUTH_TOKEN, data.access_token);
		PermissionManager.setItem(REFRESH_TOKEN, data.refresh_token);
	}
}

// Create axios instance
const axiosHttp = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		"Access-Control-Allow-Origin": "*",
	},
});

// Request interceptor
axiosHttp.interceptors.request.use(
	(config) => {
		config.headers["Authorization"] = `Bearer ${PermissionManager.getItem(AUTH_TOKEN)}`;
		config.headers["tz"] = moment().utcOffset().toString();
		config.headers["Timezone-Offset"] = moment().utcOffset().toString();

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Response interceptor
axiosHttp.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (originalRequest.url !== "/auth/login" && error.response) {
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				try {
					const refToken = getRefreshToken();
					const data = qs.stringify({
						refresh_token: refToken,
					});

					const config = {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
						},
					};

					const res = await axios.post(`${API_BASE_URL}/auth/mobile/refresh`, data, config);

					saveUser(res?.data);
					axiosHttp.defaults.headers.common["Authorization"] = `Bearer ${PermissionManager.getItem(AUTH_TOKEN)}`;

					return axiosHttp(originalRequest);
				} catch (_error) {
					PermissionManager.removeItem(AUTH_TOKEN);
					PermissionManager.removeItem(REFRESH_TOKEN);
					PermissionManager.removeItem(USER);
					PermissionManager.clear();
					window.location.href = "/";
				}
			}
			return Promise.reject(error);
		}
		return Promise.reject(error);
	}
);

export default axiosHttp;
