import axiosHttp from "services/axiosHttp";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchSummeryList = createAsyncThunk("fetchSummeryList", async ({ myLeaderShip, type }) => {
	try {
		const token = localStorage.getItem("auth_token");

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};

		return axiosHttp
			.get(`analytics/summery/detail?isLeadership=${myLeaderShip}&type=${type}`, config)
			.then((data) => {
				return data?.data;
			})
			.catch((err) => {
				return err?.response?.data;
			});
	} catch (error) {
		throw error;
	}
});

const SummeryListSlice = createSlice({
	name: "SummeryListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	reducers: {
		resetMyLeaderShipSliceState: (state) => {
			state.isLoading = false;
			state.error = false;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchSummeryList.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(fetchSummeryList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});

		builder.addCase(fetchSummeryList.rejected, (state, action) => {
			state.isLoading = false;
			state.data = null;
			state.error = action.payload;
		});
	},
});

export const { resetMyLeaderShipSliceState } = SummeryListSlice.actions;
export default SummeryListSlice.reducer;
